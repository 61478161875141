import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { AuthService } from 'src/app/@shared/services/credentials/auth.service';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
})
export class ProfileMenuComponent implements OnInit {

  constructor(
    private router: Router,
    private credentialsService : AuthService,
    private popoverController: PopoverController
  ) { }

  ngOnInit() {}

  logout(){
    this.credentialsService.signOut();
    this.popoverController.dismiss();
  }

  viewProfile(){
    this.router.navigate(['/profile']);
    this.popoverController.dismiss();

  }
}
