import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  private profileImageUrlSource = new BehaviorSubject('https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y')
  currentprofileImageUrl = this.profileImageUrlSource.asObservable();
  constructor() { }

  changeProfileImage(imageUrl: string){
    this.profileImageUrlSource.next(imageUrl);
  }
}
