import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { User } from 'src/app/api/models/user';
import { Router } from '@angular/router';

interface AuthResponse {
  token: string;
  user: User;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  private user$ = new BehaviorSubject<User | null>(null);
  private tokenKey = 'authToken';
  private iBTtokenKey = 'authIBTToken';
  private loggedInUser = 'loggedInUser';
  constructor(
    private router: Router
  ) { }

  // setUser(user: User | null): void {
  //   if (user) {
  //     user.isAdmin = user.roles.includes('admin');
  //   }

  //   this.user$.next(user);
  //   // window.user = user;
  // }

  // getUser(): Observable<User | null> {
  //   return this.user$.asObservable();
  // }

  // me(): Observable<User> {
  //   const token: string | null = this.tokenStorage.getToken();

  //   if (token === null) {
  //     return EMPTY;
  //   }

  //   return this.http.get<AuthResponse>('/api/auth/me').pipe(
  //     tap(({ user }) => this.setUser(user)),
  //     pluck('user')
  //   );
  // }

  getAuthorizationHeaders() {
    const token: string | null = this.getToken() || '';
    return { Authorization: `Bearer ${token}` };
  }

  getAuthorizationIBTHeaders() {
    const tokenUser: any = this.getIBTUser() || '';
    const token = tokenUser.access_token;
    return { Authorization: `Bearer ${token}` };
  }

  /**
   * Let's try to get user's information if he was logged in previously,
   * thus we can ensure that the user is able to access the `/` (home) page.
   */
  // checkTheUserOnTheFirstLoad(): Promise<User> {
  //   return this.me().toPromise();
  // }
  signOut(): void {
    localStorage.removeItem(this.tokenKey);
    localStorage.clear();
    // this.setUser(null);
    // delete window.user;
    this.router.navigate(['auth/login'], {
      replaceUrl: true
    });
    // this.router.routeReuseStrategy.shouldReuseRoute = function() {
    //   return false;
    //   };
  }

  saveToken(token?: string): void {
    if (!token) return;
    localStorage.setItem(this.tokenKey, token);
  }

  saveLoginUser(user?: any): void {
    localStorage.setItem(this.loggedInUser, JSON.stringify(user));
  }
  saveIBTUser(user?: any): void {
    localStorage.setItem(this.iBTtokenKey, JSON.stringify(user));
  }

  getLoggedInUser(): any {
    return localStorage.hasOwnProperty(this.loggedInUser) ? JSON.parse(localStorage.getItem(this.loggedInUser)) : '';
  }
  getIBTUser(): string | null {
    return localStorage.hasOwnProperty(this.iBTtokenKey) ? JSON.parse(localStorage.getItem(this.iBTtokenKey)) : '';
  }


  getToken(): string | null {
    return localStorage.hasOwnProperty(this.tokenKey) ? localStorage.getItem(this.tokenKey) : '';
  }

  checkCanActivateRoute(url: string) {
    const user = this.getLoggedInUser();
    if (user.userType === 'Employee' && user.roles?.length == 0) {
      return true;
    } else if (user.userType === 'Employee') {
      if (url.toLocaleLowerCase().indexOf('employee') >= 0) {
        return false;
      } else if (url.toLocaleLowerCase().indexOf('container-reservation') >= 0) {
        return false;
      } else {
        return true;
      }
    } else {
      if (url.toLocaleLowerCase().indexOf('client') >= 0) {
        return false;
      } else if (url.toLocaleLowerCase().indexOf('employee') >= 0) {
        return false;
      } else {
        return true;
      }
    }
  }
}
