import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ShellPageRoutingModule } from './shell-routing.module';

import { ShellPage } from './shell.page';
import { TranslateModule } from '@ngx-translate/core';
import { ProfileMenuComponent } from './profile-menu/profile-menu.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    // ShellPageRoutingModule,
    RouterModule,
    TranslateModule.forChild()
  ],
  declarations: [ShellPage, ProfileMenuComponent]
})
export class ShellPageModule {}
