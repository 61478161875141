import { Injectable } from '@angular/core';
import { Route, Routes } from '@angular/router';
import { ShellPage } from './shell.page';
import { AuthGuard } from '../auth/auth-guard';

@Injectable({
  providedIn: 'root'
})
export class ShellService {

  constructor() { }

  /**
   * Creates routes using the 
   * @param routes The routes to add.
   */
  static childRoutes(routes: Routes): Route {
    return {
      path: '',
      component: ShellPage,
      children: routes,
      canActivate: [AuthGuard],
      // Reuse ShellComponent instance when navigating between child views
      data: { reuse: true },
    };
  }
}
