import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../@shared/services/credentials/auth.service';

@Injectable({
    providedIn: 'root'
  })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {
  }

  canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
      if(this.authService.getToken()) {
        return this.authService.checkCanActivateRoute(state.url);
      } else {
        this.router.navigate(['/auth/login'], {
          replaceUrl: true
        });
        return false;
      }
  }
}