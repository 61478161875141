import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ShellService } from './shell/shell.service';
import { AuthGuard } from './auth/auth-guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthPageModule),
  },
  {
    path: 'ble',
    loadChildren: () => import('./ble/ble.module').then(m => m.BleLoggerPageModule),
    canActivate: [AuthGuard]
  },
  ShellService.childRoutes([
    {
      path: '',
      redirectTo: 'dashboard',
      pathMatch: 'full'
    },
    {
      path: 'profile',
      loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'claims',
      loadChildren: () => import('./claims/claims.module').then(m => m.ClaimsPageModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'employee',
      loadChildren: () => import('./employee/employee.module').then(m => m.EmployeePageModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'client',
      loadChildren: () => import('./client/client.module').then(m => m.ClientPageModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'data-logger',
      loadChildren: () => import('./data-logger/data-logger.module').then(m => m.DataLoggerPageModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'dashboard',
      loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardPageModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'profile',
      loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'container-reservation',
      loadChildren: () => import('./container-reservation/order.module').then(m => m.OrderPageModule),
      canActivate: [AuthGuard]
    },
  ]),
  // Fallback when no prior route is matched
  {
    path: '**',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
