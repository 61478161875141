import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public changePassword = `/api/user/updateUserPassword`;
  public updateProfile = `/api/user/updateUserProfileImage`;
  public getUserProfile = `api/user/getUserProfileImage`;
  public resetUserPasswordEndPoint = `api/user/resetUserPassword`;
  constructor(private http: HttpClient) { }

  updatePassword(data: any):Observable<any>{
    return this.http.post<any>(`${this.changePassword}`,data);
  }

  updateUserProfileImage(data: FormData): Observable<any> {
    return this.http.post<any>(`${this.updateProfile}`, data);
  }
  
  resetUserPassword(data: any): Observable<any> {
    return this.http.post<any>(`${this.resetUserPasswordEndPoint}`, data);
  }

  getUserProfileImage(fileName: string): Observable<any> {
    return this.http.get(`${this.getUserProfile}`, {
      responseType: 'blob',
      params: {
        fileName: fileName
      }
    });
  }
}
