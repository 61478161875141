// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  itbUserId: 'pankaj@ithrue.com',
  itbUserPwd: 'Icebattery1!',
  defaultLang: 'en',
  trustedAPILimit: 50000,
  // trustedAPILimit: 100,
  limit: 10,
  skip: 10,
  defaultTimeZoneLocale: 'en-IN',
  role: ["Admin","Manager", "End User"],
  DYNAMODB_ENDPOINT: undefined,
  DYNAMODB_ACCESS_KEY_ID: "AKIAV3GW7CQLLYVJY27J",
  DYNAMODB_SECRET_ACCESS_KEY: "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",
  TZONE_IP: "www.ibtrace.com/tzoneserver",
  TZONE_PASS: "titcod-Bossy7-kecdyr",
  USER_NAME: "Ite-admin",
  USER_ID: "clsfycg1900035mehoxecjyp1",
  TZONE_TOKEN: "",
  TZONE_PASS_INDIA: "IBTG4#",
  USER_NAME_INDIA: "IB-TG4",
  USER_ID_INDIA: "clsajigkw000kuo6i63wwlm5h",
  MAIL_INDIA: "iteCustomer4@tzone.com",
  TZONE_TOKEN_INDIA: "",
  TZONE_TOKEN_TIMESTAMP: ""
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
