import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { AuthService } from '../services/credentials/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log("**************ibt******inter")
        if(req.url.indexOf('https://api.trusted.dk') >= 0){
            // console.log("**************ibt")
            if(req.url.indexOf('Token') >= 0) {
                //  console.log('------if=============');
                return next.handle(req);
            } else {
                req = req.clone({
                    setHeaders: this.authService.getAuthorizationIBTHeaders(),
                });
                return next.handle(req);
            }
        } else if (req.url.indexOf('https://www.ibtrace.com/tzoneserver/api/v1') >= 0) {
            // Clone the request to create a new one without the "Authorization" header
         const noAuthRequest = req.clone({
            headers: req.headers.delete('Authorization')
          });
      
          // Send the new request without the "Authorization" header
          return next.handle(noAuthRequest).pipe(
            catchError((error) => {
              if (error.status === 0) {
                // Handle specific error for CORS preflight requests
                return throwError(() => new Error('CORS preflight request failed. Please check your server configuration.'));
              }
              return throwError(() => error);
            })
          );
        } else {
            req = req.clone({
                setHeaders: this.authService.getAuthorizationHeaders(),
            });
            return next.handle(req);
        }
         
    }

}